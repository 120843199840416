import React, { useState } from "react"
import Footer from "../../components/footer"
import { graphql } from "gatsby"
import { Solution } from "../products/bare-metal-cloud"
import "../../styles/hybrid-cloud.less"
import SEO from "../../components/SEO"
import { Tab } from "../../components/ui"
import { SolutionTopBanner, ProductSupport } from "../../components/block"
import { Client } from "./gaming"
import Drop from "../../components/drop"
import { Usecase } from "../products/cloud-wan"
const loadText = require("src/utils").loadText
import { ConsultationPopButton } from "../../components/form-biz"
const data = loadText("hybrid-cloud")
const listColors = [
  "#FFE581",
  "#77E6FF",
  "#71DAD4",
  "#E2C0FE",
  "#FFDEC0",
  "#FFC2F1",
  "#C1CBFE",
]
const Feature = () => {
  return (
    <div className="hybrid-cloud-feature">
      <h2 className="font-36 semi-bold t-color">{data.feature.title}</h2>
      <div className="hybrid-cloud-feature-content">
        {data.feature.content.map(({ name, bgColor, description }, idx) => (
          <div
            style={{ background: bgColor }}
            className="hybrid-cloud-feature-itemContainer"
          >
            <div
              className="hybrid-cloud-feature-item"
              style={{
                backgroundImage: `url("/solution/hybrid-cloud/feature-m-${
                  idx + 1
                }.svg")`,
              }}
            >
              <h3
                className="font-36 t-color semi-bold"
                dangerouslySetInnerHTML={{ __html: name }}
              ></h3>
              <div
                dangerouslySetInnerHTML={{ __html: description }}
                className="description font-16 mt-24 content"
                style={{
                  lineHeight: "28px",
                }}
              ></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
const BottomBanner = () => {
  return (
    <div className="hybrid-cloud-bottomBanner">
      <div className="hybrid-cloud-bottomBanner-content full-bg">
        <div className="page-middle">
          <h2 className="font-36 semi-bold title white">
            {data.bottomBanner.title}
          </h2>
          <div className="mt-32">
            <ConsultationPopButton buttonClassName="white" source="hybridCloud">
              {data.bottomBanner.buttonText}
            </ConsultationPopButton>
          </div>
        </div>
      </div>
    </div>
  )
}
const Architecture = () => {
  const Item = ({ img, architectures, results }) => (
    <div className="hybrid-cloud-architecture-item">
      <div className="hybrid-cloud-architecture-itemTop">
        <div className="picContainer">
          <img src={img} alt="" />
        </div>
        <div className="textContainer">
          <ul>
            <div className="font-20 t-color semi-bold">
              {data.subject.architecture}
            </div>
            {architectures.map((t: string, idx: number) => (
              <li className="mt-16 font-16" style={{ color: listColors[idx] }}>
                <span
                  style={{ color: "#616E82" }}
                  dangerouslySetInnerHTML={{
                    __html: t.replace(/\{\w+\}/g, (match: string) => {
                      const key = match.slice(1, -1)
                      return `"/solution/hybrid-cloud/${key}.svg" alt="${key}"`
                    }),
                  }}
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
      <ul className="hybrid-cloud-architecture-itemBottom">
        {/* <Drop
          color="#fff"
          bgColor="#F5F9FF"
          size="l"
          style={{ top: "-32px", position: "absolute" }}
          direction="up"
        /> */}
        <div className="title font-20 t-color semi-bold mb-32">
          {data.subject.result}
        </div>
        {results.map(({ content, img }) => (
          <div className="content font-16 bottom-item">
            <img src={img} alt={content} />
            <div className="contentText mt-16">{content}</div>
          </div>
        ))}
      </ul>
    </div>
  )
  const [active, setActive] = useState(data.architecture.content[0].name)
  return (
    <div className="hybrid-cloud-architecture">
      <div className="hybrid-cloud-architecture-content page-middle">
        <h2 className="font-36 semi-bold text-center t-color">
          {data.architecture.title}
        </h2>
        <div className="text-center mt-56">
          <Tab
            onChange={key => {
              setActive(key)
            }}
            active={active}
            tabs={data.architecture.content.map(
              ({ name, img, architectures, results }) => ({
                key: name,
                name: <p className="tab-name">{name}</p>,
                component: (
                  <Item
                    img={img}
                    architectures={architectures}
                    results={results}
                  />
                ),
              })
            )}
          />
        </div>
      </div>
    </div>
  )
}

export default function Home() {
  return (
    <div className="hybrid-cloud">
      <SEO {...data.seo} featuredImage="/featuredImage/hybrid-cloud.jpg" />
      <SolutionTopBanner
        {...data.topBanner}
        className="hybrid-cloud-banner"
        backgroundAnimated={true}
        source="hybridCloud"
        bgColor="linear-gradient(182deg,rgba(199,236,255,1) 0%,rgba(238,249,255,1) 100%)"
        bgPic={"/solution/hybrid-cloud/topBanner-bg.svg"}
        bannerPic={{
          src: `/solution/hybrid-cloud/topBanner-m-bg.svg`,
        }}
        pic={{
          src: "/solution/hybrid-cloud/topBanner-pic.png",
          style: {
            position: "absolute",
            bottom: "104px",
            right: "64px",
            width: "587px",
          },
        }}
      />
      <Feature />
      <Client />
      <Architecture />

      <Usecase
        {...data.benefits}
        content={data.benefits.content.map((d, i) => ({
          ...d,
          img: `/solution/hybrid-cloud/usecase-${i + 1}.svg`,
        }))}
      />
      <ProductSupport
        source="hybridCloud"
        style={{ margin: "12px 0 104px 0" }}
        bgPic="/solution/hybrid-cloud/support-bg.svg"
        woman="/solution/hybrid-cloud/support-woman.png"
        bgColor="#F5F9FF"
      />
      <Solution {...data.solution} />
      <BottomBanner />
      {/* <GetStarted /> */}
      <Footer />
    </div>
  )
}
